section.v-products-box {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    .title {
        padding: 5px;
        font-family: var(--primary-font-family);
        font-size: 20px;
        background: var(--ion-color-coffee);
        color: var(--ion-color-light);
        text-align: center;
        margin-bottom: 10px;
    }

    .btn-container {
        text-align: center;
        padding: 10px;
    }
    ion-item.product{
        margin-bottom: 10px;
    }
}
