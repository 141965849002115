.quick-view {

    .btn-order {
        @media screen and (max-width:1024px) {
            width: 100%;
        }
    }

    .attr-selector {
        width: 100%;
        --background: rgba(var(--ion-color-tertiary-rgb, 0.5), 0.3);
    }

    .stock {
        margin-top: 20px;
        font-size: 1rem;
    }

    .buttons {
        @media screen and (min-width:500px) {
            display: grid;
            grid-template-columns: 50% 50%;
        }
    }

    .quantity {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        ion-input {
            text-align: center;

            input {
                padding: 10px;
            }
        }

        >* {
            margin-bottom: 10px;
        }
    }

}
