#print-page{
    .uploader{
        max-width: 700px;
        background: #F1F1F1;
        text-align: center;
        padding: 100px;
        border: 2px dashed rgb(86, 86, 206);
        border-radius: 10px;
        margin: 10px auto;
        cursor: pointer;
        .icon{
            font-size: 50px;
        }
        h3{
            font-size: 20px;
            font-weight: 600;
        }
        &:hover{
            background: #d1cfcfc2;
        }
    }
    .page-count{
        margin: 100px 0;
        text-align: center;
        h3{
            font-size: 30px;
            font-weight: 600;
        }
        h1{
            margin-top: 20px;
            font-size: 50px;
            font-weight: 600;
        }
    }
}
