#single-product {
    @media screen and (min-width:1025px) {
        ion-grid {
            --ion-grid-column-padding: 20px;
        }
    }
    ion-card{
        margin: 0 0 10px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .col {
            width: 49%;

            @media screen and (max-width:1024px) {
                width: 48%;

                &.category {
                    display: none;
                }
            }

            @media screen and (max-width:425px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .swiper-slide {
        text-align: center;
        background: var(--ion-color-light-shad);

        a {
            display: block;
        }
    }

    h1.title {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: 0px;

    }

    .meta {
        .pricing {
            line-height: 1.3;
            font-family: var(--primary-font-family);
            margin-bottom: 10px;

            .regular {
                text-decoration: line-through;
                margin-left: 20px;
                font-size: 0.8rem;
            }

            .sale {
                font-size: 1.5rem;
                font-weight: 700;
            }
        }
    }


    .attribute {
        margin-top: 20px;
        h2{
            font-weight: bold;
        }
        a {
            text-decoration: none;
        }

        svg {
            margin: 0 10px;
        }
    }

}
