/* frontend/src/pages/ComparePage.scss */

#compare-page {
    padding: 20px;

    &.compare-loading,
    &.compare-no-products {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80vh;
      text-align: center;

      h1 {
        margin-bottom: 20px;
        font-size: 2rem;
      }

      p {
        margin-bottom: 20px;
        font-size: 1.2rem;
      }
    }

    .compare-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h1 {
        font-size: 2rem;
        margin: 0;
      }

      ion-button {
        font-size: 1rem;
      }
    }

    .table-responsive {
      overflow-x: auto;

      .compare-table {
        width: 100%;
        border-collapse: collapse;
        min-width: 600px;

        th,
        td {
          border: 1px solid #ddd;
          padding: 12px;
          text-align: center;
          vertical-align: middle;
          a{
            display: block;
            display: flex;
            justify-content: center;
          }
        }

        th {
          background-color: #f2f2f2;
          color: black;

          .compare-product-image {
            width: 100px;
            height: auto;
            margin-bottom: 10px;
          }

          p {
            margin: 5px 0;
            font-weight: bold;
          }
        }

        td {
          font-size: 1rem;

        }

        // Responsive adjustments
        @media screen and (max-width: 768px) {
          th,
          td {
            padding: 8px;
          }

          th {
            .compare-product-image {
              width: 80px;
            }

            p {
              font-size: 1rem;
            }
          }

          td {
            font-size: 0.9rem;
          }
        }
      }
    }

    ion-button {
      // Optional: Customize button styles within the compare page
      &.remove-button {
        // Styles specific to remove buttons if needed
      }
    }
  }
